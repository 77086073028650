const modalJsFile = require('_fil/global-cms-modal/modal');

$(document).ready(() => {
  $('.fil-cards .mini-promo').click(function() {
    window.location = $(this)
      .find('.cta-section a')
      .attr('href');
    return false;
  });

  $('.cta-section a').on('click', function() {
    const modal_id = $(this).attr('data-open');
    modalJsFile.modalButtonEvent(modal_id);
  });
});

$(window).load(function() {
  $('.fil-cards [data-equalizer]').trigger('resizeme.zf.trigger');
});
